import { Controller } from "@hotwired/stimulus"

const formatCentsToMoney = (amountCents) => {
  const dollars = amountCents / 100;
  return `$${dollars.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
}

const MIN_AMOUNT_INSURED_CENTS = 10000; // $100.00
const MAX_AMOUNT_INSURED_CENTS = 1500000; // $15,000.00

const DEFAULT_COVERAGE_MULTIPLIER_OPTIONS = [...Array(10).keys()].map(i => (i + 1) * 100000);

// Connects to data-controller="loss-of-employment-form"
export default class extends Controller {
  static targets = [
    "step",
    "amountInsuredCentsInput",
    "multiplierSelect",
    "monthlyRentInput"
  ]
  static values = {
    isErrorPresent: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.currentStep = this.isErrorPresentValue ? 1 : 0;
    this.showCurrentStep();

    if (this.monthlyRentInputTarget.value) {
      this.updateMonthlyRent({ target: { value: this.monthlyRentInputTarget.value } });
    }
  }

  continueToPayment(e) {
    e.preventDefault();
    if (!this.validateCurrentStep()) return;

    this.saveAbandonedCart(this.element);
    this.element.requestSubmit();
  }

  nextStep(e = null) {
    if (e) e.preventDefault();

    if (!this.validateCurrentStep()) return;

    if (this.currentStep < this.stepTargets.length - 1) {
      this.currentStep++
      this.showCurrentStep()
      this.saveAbandonedCart(this.element)
    }
  }

  saveAbandonedCart = (form) => {
    const formData = new FormData(form)
    const data = Object.fromEntries(formData)
    const email = data['loss_of_employment_policy[email]']

    fetch('/loss_of_employment_policies/abandoned_carts', {
      method: 'POST',
      headers: new Headers({'content-type': 'application/json'}),
      body: JSON.stringify({ authenticity_token: data['authenticity_token'], abandoned_cart: { email, data }})
    })
  }

  prevStep(e) {
    e.preventDefault();
    if (this.currentStep > 0) {
      this.currentStep--
      this.showCurrentStep()
    }
  }

  validateCurrentStep() {
    let allValid = true;

    // check for any invalid or empty fields
    this.stepTargets[this.currentStep].querySelectorAll("[required]").forEach(field => {
      let fieldValid = field.reportValidity() && !!field.value;
      fieldValid ? field.classList.remove("has-error") : field.classList.add("has-error");
      if (!fieldValid) allValid = false;
    });

    // check for any validation errors
    if (this.stepTargets[this.currentStep].querySelector(".validation-error")) {
      allValid = false;
    }

    return allValid;
  }

  showCurrentStep() {
    this.stepTargets.forEach((step, index) => {
      if (index === this.currentStep) {
        step.classList.remove("hidden");
        this.enableRequiredFields(step);
        step.querySelector("input, select").focus();
      } else {
        step.classList.add("hidden");
        this.disableRequiredFields(step);
      }
    })
  }

  disableRequiredFields(step) {
    step.querySelectorAll("[required]").forEach(field => {
      field.dataset.required = "true";
      field.removeAttribute("required");
    });
  }

  enableRequiredFields(step) {
    step.querySelectorAll("[data-required]").forEach(field => {
      field.setAttribute("required", "true");
    });
  }

  updateMonthlyRent(event) {
    this.monthlyRent = parseInt(event.target.value);
    const rentIsValid = this.monthlyRent >= MIN_AMOUNT_INSURED_CENTS / 100 && this.monthlyRent <= MAX_AMOUNT_INSURED_CENTS / 100;

    this.multiplierSelectTarget.innerHTML = '';

    let coverage_amount_options;

    if (rentIsValid) {
      coverage_amount_options =
        [...Array(10).keys()].map(i => this.monthlyRent * 100 * (i + 1)).filter(i => i <= MAX_AMOUNT_INSURED_CENTS);
    } else if (this.monthlyRent * 100 > MAX_AMOUNT_INSURED_CENTS) {
      coverage_amount_options = [MAX_AMOUNT_INSURED_CENTS];
    }else {
      coverage_amount_options = DEFAULT_COVERAGE_MULTIPLIER_OPTIONS;
    }

    if (coverage_amount_options.length === 0) coverage_amount_options = [MAX_AMOUNT_INSURED_CENTS];

    coverage_amount_options.map((amount_cents, index) => {
      const newOption = document.createElement('option');
      newOption.value = amount_cents;
      newOption.textContent = formatCentsToMoney(amount_cents);
      if (rentIsValid) newOption.textContent += ` (${index + 1}X monthly rent)`;
      this.multiplierSelectTarget.appendChild(newOption);
    });

    this.multiplierSelectTarget.value = coverage_amount_options.slice(0, 3).filter(a => a).reverse()[0];
    this.multiplierSelectTarget.dispatchEvent(new Event('change'));
  }

  setAmountInsuredCents(event) {
    let newValueCents = 0;

    if (event.target.id === 'loss_of_employment_policy_amount_insured_cents_input') {
      newValueCents = event.target.value * 100
    } else {
      newValueCents = event.target.value
    }

    this.amountInsuredCentsInputTarget.value = newValueCents;
    this.amountInsuredCentsInputTarget.dispatchEvent(new Event('change'));
  }
}

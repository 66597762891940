import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="phone-number-input-mask"
export default class extends Controller {
  static targets = ["maskInput", "hiddenInput"];

  connect() {
    if (this.hiddenInputTarget.value) {
      this.maskInputTarget.value = this.formatToPhoneNumber(this.hiddenInputTarget.value);
    }
  }

  formatPhoneNumber(event) {
    const input = event.target;
    const value = input.value.replace(/\D/g, "").slice(0, 10); // Remove all non-digit characters
    const formattedValue = this.formatToPhoneNumber(value);

    input.value = formattedValue;
    this.hiddenInputTarget.value = value;
  }

  formatToPhoneNumber(value) {
    const areaCode = value.slice(0, 3);
    const middle = value.slice(3, 6);
    const last = value.slice(6, 10);

    if (value.length > 6) {
      return `${areaCode}-${middle}-${last}`;
    } else if (value.length > 3) {
      return `${areaCode}-${middle}`;
    } else if (value.length > 0) {
      return `${areaCode}`;
    }

    return value;
  }
}

import { Controller } from "@hotwired/stimulus"

/* Mask Currency USD Controller:
  - Formats a text input to display a currency value in USD
  - Updates a hidden field with the value in cents for submission
  - Only allows numeric input

  Connects to data-controller="mask-currency-usd"

  Example usage:

    = form.text_field :amount_masked, data: { controller: "mask-currency-usd", mask_currency_usd_hidden_field_id: "unique_id_for_hidden_field" }
    = form.hidden_field :amount, required: true, id: "unique_id_for_hidden_field"
*/

export default class extends Controller {
  static values = {
    prefix: {
      type: String,
      default: '$',
    }
  }

  initialize() {
    this.onInput.bind(this);
  }

  connect() {
    let hiddenFieldId = this.element.getAttribute('data-mask-currency-usd-hidden-field-id');
    if (!hiddenFieldId) {
      console.error('No hidden field ID provided for mask currency USD controller');
      return;
    }
    this.hiddenFieldElement = document.getElementById(hiddenFieldId);

    this.useCents = this.element.getAttribute('data-mask-currency-use-cents') !== "false";
    this.displayCents = this.useCents && this.element.getAttribute('data-mask-currency-display-cents') !== "false";

    this.amountValue = 0;
    const initialAmount = this.hiddenFieldElement.value;

    if (initialAmount) {
      this.amountValue = this.convertToDollars(initialAmount);
      this.updateOutput();
    }

    this.element.addEventListener('input', e => this.onInput(e));
  }

  disconnect() {
    this.element.removeEventListener('input', this.onInput);
  }

  onInput(event) {
    const input = event.target;
    const inputValue = this.removeNonDigitCharacters(input.value);

    this.amountValue = this.convertToDollars(inputValue);

    this.updateOutput();
    this.updateHiddenField();
  }

  removeNonDigitCharacters(string) {
    return string.replace(/\D/g, '');
  }

  convertToDollars(cents) {
    const amount = parseInt(cents || 0);

    if (this.displayCents) {
      return amount / 100;
    } else {
      return amount;
    }
  }

  updateOutput() {
    const formattedAmount = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: this.displayCents ? 2 : 0,
    }).format(this.amountValue);

    this.element.value = `${this.prefixValue}${formattedAmount}`;
    this.element.dispatchEvent(new Event('change'));
  }

  updateHiddenField() {
    if (this.hiddenFieldElement) {
      let amountCents = parseInt(this.element.value.replace(/\D/g, ''));
      if (this.displayCents || !this.useCents) {
        this.hiddenFieldElement.value = amountCents;
      } else {
        this.hiddenFieldElement.value = amountCents * 100;
      }
      this.hiddenFieldElement.dispatchEvent(new Event('change'));
    }
  }
}
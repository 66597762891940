import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-validation"
export default class extends Controller {
  validateField(event) {
    if (event.target.value === "") return;

    const input = event.target;
    const fieldName = input.dataset.field;
    const frameId = `validation_${fieldName}`;
    const turboFrame = document.getElementById(frameId);

    if (turboFrame) {
      turboFrame.src = `/loss-of-employment-insurance/validate_field?field=${fieldName}&loss_of_employment_policy[${fieldName}]=${encodeURIComponent(input.value)}`;
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dialog"
export default class extends Controller {
  static values = { lightDismissEnabled: { default: false, type: Boolean } }
  static targets = ["modal", "content"]

  connect() {
    if (this.lightDismissEnabledValue) this.modalTarget.addEventListener("click", this.lightDismiss.bind(this));
  }

  disconnect() {
    if (this.lightDismissEnabledValue) this.modalTarget.removeEventListener("click", this.lightDismiss);
  }

  open() {
    this.modalTarget.showModal();
    document.body.classList.add("overflow-hidden");
  }

  close() {
    this.modalTarget.close();
    document.body.classList.remove("overflow-hidden");
  }

  lightDismiss(e) {
    var rect = e.target.getBoundingClientRect();
    var minX = rect.left + e.target.clientLeft;
    var minY = rect.top + e.target.clientTop;
    if ((e.clientX < minX || e.clientX >= minX + e.target.clientWidth) ||
        (e.clientY < minY || e.clientY >= minY + e.target.clientHeight)) {
      this.close();
    }
  }
}
